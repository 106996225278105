import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'homeEnNew',
    component: () => import('@/views/homeEnNew.vue')
  },
  // {
  //   path: '*',
  //   name: 'loginExpired',
  //   component: () => import('@/views/homeEnNew.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
