<template>
    <div id="app">
      <div class="app_com">
        <router-view/>
      </div>
    </div>
</template>
<script>
    export default{
        name: 'App',
    }
</script>
<style lang="scss">
  html, body{
    padding: 0!important;
    overflow: hidden;
  }
  #app{
    background-color: #f5f5f5;
  }
</style>
